<main id="home">
  <section id="slider">
      <img class="img-fluid" src="assets/img/slide-1.jpg" alt="slider image">
      <div class="text-block">
          <h2>SeaLife</h2>
          <p class="hidden-md-down">
              restoring the ocean and the wildlife that is in it
          </p>
          <a class="btn" href="#"> see our recent projects</a>
      </div>
  </section>

  <section id="components" class="container wide">
      <div class="row">
          <div class="col-md-4 col-sm-6 pad ">
              <div class="component">
                  <div class="comp front">
                      <img class="img-fluid" src="assets/img/comp-1.jpg" alt="">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <span class="fa fa-recycle"></span>
                          </div>
                          <div class="block-2">
                              <h4>recycling waste</h4>
                          </div>
                      </div>
                  </div>
                  <div class="comp back">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <h4>recycling waste</h4>
                          </div>
                          <div class="block-2">
                              <p class="fluid-max-3 text-center">
                                  Cred wolf hoodie, four loko butcher knausgaard tousled bespoke ugh pinterest vaporware enamel pin.
                              </p>
                          </div>
                          <div class="block-3">
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
          <div class="col-md-4 col-sm-6  pad ">
              <div class="component">
                  <div class="comp front">
                      <img class="img-fluid" src="assets/img/comp-2.jpg" alt="">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <span class="fa fa-cloud"></span>
                          </div>
                          <div class="block-2">
                              <h4>Wind Turbines</h4>
                          </div>
                      </div>
                  </div>
                  <div class="comp back">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <h4>Wind Turbines</h4>
                          </div>
                          <div class="block-2">
                              <p class="fluid-max-3 text-center">
                                  You probably haven't heard of them salvia street art, migas iceland single-origin
                              </p>
                          </div>
                          <div class="block-3">
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
          <div class="col-md-4 col-sm-6 pad">
              <div class="component">
                  <div class=" comp front">
                      <img class="img-fluid" src="assets/img/comp-3.jpg" alt="">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <span class="fa fa-leaf"></span>
                          </div>
                          <div class="block-2">
                              <h4>preserving wildlife</h4>
                          </div>
                      </div>

                  </div>
                  <div class=" comp back">
                      <div class="bg">

                      </div>
                      <div class="text">
                          <div class="block-1">
                              <h4>preserving wildlife</h4>
                          </div>
                          <div class="block-2">
                              <p>
                                  Chillwave meh try-hard forage, tote bag trust fund kale chips neutra narwhal tacos leggings freegan poke. Fingerstache lumbersexual kogi, pour-over +1
                              </p>
                          </div>
                          <div class="block-3">
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-sm-6 pad">
              <div class="component">
                  <div class=" comp front">
                      <img class="img-fluid" src="assets/img/comp-4.jpg" alt="">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <span class="fa fa-globe"></span>
                          </div>
                          <div class="block-2">
                              <h4>beach rejuvination</h4>
                          </div>
                      </div>

                  </div>
                  <div class=" comp back">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <div class="block-1">
                              <h4>beach rejuvination</h4>
                          </div>
                          <div class="block-2">
                              <p>
                                  art party man bun four dollar toast. Hoodie crucifix keffiyeh, kickstarter austin wayfarers cliche subway
                              </p>
                          </div>
                          <div class="block-3">
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-sm-6 pad">
              <div class="component">
                  <div class=" comp front">
                      <img class="img-fluid" src="assets/img/comp-5.jpg" alt="">
                      <div class="bg">

                      </div>
                      <div class="text">
                          <div class="block-1">
                              <span class="fa fa-plug"></span>
                          </div>
                          <div class="block-2">
                              <h4>oilspill cleanup</h4>
                          </div>
                      </div>

                  </div>
                  <div class=" comp back">

                      <div class="bg">

                      </div>
                      <div class="text">
                          <div class="block-1">
                              <h4>oilspill cleanup</h4>
                          </div>
                          <div class="block-2">
                              <p>
                                  Locavore copper mug adaptogen, fanny pack whatever tattooed organic knausgaard direct trade iPhone yr disrupt coloring book
                              </p>
                          </div>
                          <div class="block-3">
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-sm-6 pad">
              <div class="component">
                  <div class=" comp front">
                      <img class="img-fluid" src="assets/img/comp-6.jpg" alt="">
                      <div class="bg">

                      </div>
                      <div class="text">
                          <div class="block-1">
                              <span class="fa fa-bolt"></span>
                          </div>
                          <div class="block-2">
                              <h4>ocean energy</h4>
                          </div>
                      </div>

                  </div>
                  <div class=" comp back">
                      <div class="bg">

                      </div>
                      <div class="text">
                          <div class="block-1">
                              <h4>ocean energy</h4>
                          </div>
                          <div class="block-2">
                              <p>
                                  meditation wayfarers. Mustache normcore brunch shoreditch, mixtape master cleanse knausgaard bespoke cloud bread raw denim iceland.
                              </p>
                          </div>
                          <div class="block-3">
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- <div class="row"> -->

      <!-- </div> -->
  </section>

  <section id="offering" >
      <div class="container wide">
          <div class="row">
              <div class="col-md-6 pad padH">
                  <img class="img-fluid" src="assets/img/globe-1.png" alt="component">
              </div>
              <div class="col-md-6 pad padH">
                  <h3>What We Offer</h3>
                  <h5>
                      We’re the #1 solar energy & wind turbines provider in the States!
                  </h5>
                  <p>
                      We’re providing sales and servicing in all of the US states and territories. Our products are the most efficient and reasonably priced in the industry. The  warranties and a production guarantee are provided at no additional cost.
                  </p>
                  <div class="offering-list">
                      <div class="offering row">
                          <div class="col-md-3">
                              <div class="icon">
                                  <span class="fa fa-ship "></span>
                              </div>
                          </div>
                          <div class="col-md-9">
                              <h4>Oceanography Today</h4>
                              <p>
                                  Text
                              </p>
                          </div>
                      </div>
                      <div class="offering row">
                          <div class="col-md-3">
                              <div class="icon">
                                  <span class="fa fa-home "></span>
                              </div>
                          </div>
                          <div class="col-md-9">
                              <h4>Environment Saving Technology</h4>
                              <p>
                                  Text
                              </p>
                          </div>
                      </div>
                      <div class="offering row">
                          <div class="col-md-3">
                              <div class="icon">
                                  <span class="fa fa-tree "></span>
                              </div>
                          </div>
                          <div class="col-md-9">
                              <h4>Plant Ecology</h4>
                              <p>
                                  Text
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section id="stats">
      <div class="container wide">
          <div id='project-data' class="row">
              <div class="data-block col-md-3 col-sm-6">
                  <span class="icon fa fa-ship"></span>
                  <div class="">
                      <h4>100</h4>
                      <p>projects</p>
                  </div>
              </div>
              <div class="data-block col-md-3 col-sm-6">
                  <span class="icon fa fa-money"></span>
                  <div class="">
                      <h4>100</h4>
                      <p>funds collected</p>
                  </div>
              </div>
              <div class=" data-block col-md-3 col-sm-6">
                  <span class="icon fa fa-users"></span>
                  <div class="">
                      <h4>100</h4>
                      <p>days of help</p>
                  </div>
              </div>
              <div class="data-block col-md-3 col-sm-6">
                  <span class="icon fa fa-calendar"></span>
                  <div class="">
                      <h4>100</h4>
                      <p>volunteer</p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section id="projects">
      <div class="row no-pad project-row fluid">
          <div class="col-md-3 col-sm-6 ">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-1.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-1.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>

          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-2.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-2.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>

          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-3.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-3.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>

          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-4.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-4.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>

          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-5.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-5.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-6.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-6.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-7.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-7.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col-md-3 col-sm-6">
              <a  class="img-container" href="#">
                  <div class="image front">
                      <img class="img-fluid" src="assets/img/proj-8.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                      </div>
                  </div>
                  <div class="image back">
                      <img class="img-fluid" src="assets/img/proj-8.jpg" alt="project images">
                      <div class="bg">
                      </div>
                      <div class="text">
                          <h3>heading?</h3>
                          <p class="fluid-max-4">
                                  Summary
                          </p>
                          <span class="fa fa-arrow-circle-right"></span>
                      </div>
                  </div>
              </a>
          </div>

      </div>
  </section>

  <section id="managers">
      <h3 class="text-center">executive managers</h3>
      <div class="container wide">
          <div class="row justify-content-center">
              <div class="col-md-4 post">
                  <a  class="img-container" href="#">
                      <div class="image front">
                          <img class="img-fluid" src="assets/img/exec-1.jpg" alt="manager project">
                          <div class="bg">
                          </div>
                          <div class="text">
                              <h3>what is happening in the natural waters?</h3>
                          </div>
                      </div>
                      <div class="image back">
                          <img class="img-fluid" src="assets/img/exec-1.jpg" alt="manager project">
                          <div class="bg">
                          </div>
                          <div class="text">
                              <h3>what is happening in the natural waters?</h3>
                              <p class="fluid-max-4">
                                  Gastropub skateboard deep v normcore wolf selfies. Williamsburg XOXO scenester pork belly truffaut fingerstache. Cronut migas af four dollar toast.
                              </p>
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </a>

              </div>
              <div class="col-md-4 post">
                  <a  class="img-container" href="#">
                      <div class="image front">
                          <img class="img-fluid" src="assets/img/exec-2.jpg" alt="manager project">
                          <div class="bg">
                          </div>
                          <div class="text">
                              <h3>how to protect the endangered</h3>
                          </div>
                      </div>
                      <div class="image back">
                          <img class="img-fluid" src="assets/img/exec-2.jpg" alt="manager project">
                          <div class="bg">
                          </div>
                          <div class="text">
                              <h3>how to protect the endangered</h3>
                              <p class="fluid-max-4">
                                  Gastropub skateboard deep v normcore wolf selfies. Williamsburg XOXO scenester pork belly truffaut fingerstache. Cronut migas af four dollar toast.
                              </p>
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </a>

              </div>
              <div class="col-md-4 post">
                  <a  class="img-container" href="#">
                      <div class="image front">
                          <img class="img-fluid" src="assets/img/exec-3.jpg" alt="manager project">
                          <div class="bg">
                          </div>
                          <div class="text">
                              <h3>how many sea turtles are left?</h3>
                          </div>
                      </div>
                      <div class="image back">
                          <img class="img-fluid" src="assets/img/exec-3.jpg" alt="manager project">
                          <div class="bg">
                          </div>
                          <div class="text">
                              <h3>how many sea turtles are left?</h3>
                              <p class="fluid-max-4">
                                  Gastropub skateboard deep v normcore wolf selfies. Williamsburg XOXO scenester pork belly truffaut fingerstache. Cronut migas af four dollar toast.
                              </p>
                              <span class="fa fa-arrow-circle-right"></span>
                          </div>
                      </div>
                  </a>

              </div>
          </div>
          <div class="text-center btn-container">
              <a class="btn" href="#">See all News</a>
          </div>
      </div>
  </section>

  <section id="newsletters">
      <div class="row justify-content-center column-center">
          <h3>Newsletter</h3>
          <p class="fluid-max-wide-6 pad ">
              Just like Mark Zuckerberg founded a brand new medium of communication right out of his college dorm, we’ve founded a brand new energy source company while still in college!
          </p>
          <form class="" [formGroup]="newsletterForm">
              <div class="input-group">
                  <input type="text" name="email" formControlName="email" placeholder="enter your email">
                  <button class="fa fa-envelope-o" type="button" name="submit"></button>
              </div>
          </form>
      </div>

  </section>
</main>
