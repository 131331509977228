<header>
    <section id="top-nav" class="container wide">
        <div class="row">
            <div class="fluid-max-wide-6 information">
                <div class="address pad-half padH">
                    <strong>Address:</strong> 123 main st, miami fl, 33176
                </div>
                <div class="phone pad-half padH">
                    <strong>Phones:</strong> (800)123-0045; (800)123-0046
                </div>
            </div>


            <div class="social-links fluid-max-wide-2 pad-half padH flex-right-mobile">
               <div class="">
                 <a routerLink="login">Login</a>
                  <span class="mx-1">|</span>
                 <a routerLink="register">Register</a>
                 <span class="mx-1">|</span>
                 <a (click)="onLogout()" href="#">Logout</a>
               </div>
                <!-- <ul class="navbar-nav around">
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                    <li><a href="#"><i class="fa fa-vimeo"></i></a></li>
                    <li><a href="#"><i class="fa fa-youtube"></i></a></li>
                    <li><a href="#"><i class="fa fa-pinterest"></i></a></li>
                </ul> -->
            </div>
        </div>
    </section>
    <section id="bottom-nav" class=" container wide ">
        <div class="row justify-content-center">
            <div class="pad-half padH flex-left-mobile">
                <h1 id="logo"><a routerLink="/dashboard"><span>Sea</span>Life</a></h1>
            </div>
            <div class="fluid-max-8 pad-half padH hidden-sm-down">
                <ul class="navbar-nav around main-nav">
                    <li><a routerLink="/">home</a></li>
                    <li><a routerLink="/pages">pages</a></li>
                    <li><a routerLink="/services">services</a></li>
                    <li><a routerLink="/projects">projects</a></li>
                    <li><a routerLink="/news">latest news</a></li>
                    <li><a routerLink="/contact">contacts</a></li>
                </ul>
            </div>
            <div class="pad-half padH flex-right hidden-sm-down search-container">
                <button class="search-btn" type="button" name="button"><i class="fa fa-search"></i></button>
            </div>
        </div>
    </section>
</header>
<div id="intersection-header"></div>
